<template>
    <div class="header">
        <transition name="dark">
            <div v-if="menuOpened" class="dark" />
        </transition>
        <div class="header__inner">
            <workle-logo class="header__logo" />
            <div class="header__anchors">
                <a
                    v-for="item in anchors"
                    class="header__link"
                    :href="item.href"
                    :key="item.href"
                    @click="scrollToEllement"
                    v-text="item.text"
                />
            </div>
            <ul class="header__horizontal-buttons">
                <w-button
                    class="header__button-item header__button-item--low"
                    outline
                    :href="'https://lk.workle.ru/auth/login'"
                >
                    Войти
                </w-button>
                <w-button class="j-reg_init j-reg_partner header__button-item header__button-item--wide header__button-item--low">
                    Зарегистрироваться
                </w-button>
            </ul>
        </div>
        <nav class="header__navigation">
            <div class="navigation__side-menu">
                <input class="navigation__checkbox" type="checkbox" />
                <input
                    class="navigation__checkbox"
                    type="checkbox"
                    @click="toggleSideMenu"
                />
                <span class="navigation__icon" />
                <span class="navigation__icon" />
                <span class="navigation__icon" />
                <ul class="navigation__menu">
                    <div
                        :class="{
                            'navigation__menu-slide': true,
                        }"
                    >
                        <div class="navigation__menu-left">
                            <a
                                v-for="item in anchors"
                                class="header__link navigation__link"
                                :href="item.href"
                                :key="item.href"
                                @click="scrollToEllement"
                                v-text="item.text"
                            />
                            <a
                                class="header__link navigation__link"
                                href="/help-center"
                            >
                                Ответы на вопросы
                            </a>
                            <a class="navigation__button">
                                <w-button
                                    class="navigation__button-item header__button-item--low"
                                    outline
                                    :href="'https://lk.workle.ru/auth/login'"
                                >
                                    Войти
                                </w-button>
                            </a>
                            <a class="navigation__button">
                                <w-button class="j-reg_init j-reg_partner navigation__button-item header__button-item--low">
                                    Зарегистрироваться
                                </w-button>
                            </a>
                        </div>
                    </div>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
    import { WButton } from 'Utils/ui'
    import WorkleLogo from 'Components/assets/logo.svg?component'
    import ArrowIcon from 'Components/assets/arrow.svg?component'
    import ArrowBackIcon from 'Assets/icons/arrow-right.svg?component'

    export default {
        name: 'HomeHeader',
        components: {
            WButton,
            WorkleLogo,
            ArrowIcon,
            ArrowBackIcon
        },
        data () {
            return {
                showPartnersList: false,
                menuOpened: false,
                anchors: [
                    {
                        text: 'Как начать',
                        href: '#steps'
                    },
                    {
                        text: 'Вебмастерам',
                        href: 'https://www.workle.ru/content/dlya-webmasterov/'
                    },
                    {
                        text: 'Рекламодателям',
                        href: 'https://partner.ng.workle.ru/'
                    },
                    {
                        text: 'Отзывы',
                        href: '#users'
                    },
                    {
                        text: 'О нас',
                        href: '#about'
                    },
                    {
                        text: 'Блог',
                        href: 'https://www.workle.ru/content/web-master-blog/'
                    }
                ],
                links: [
                    {
                        text: 'Страхование',
                        href: '/content/b2b-insurance/'
                    },
                    {
                        text: 'Поиск персонала',
                        href: '/content/b2b-hr/'
                    },
                    {
                        text: 'Умный колл-центр',
                        href: '/content/b2b-call-center/'
                    },
                    {
                        text: 'Финансы',
                        href: '/content/b2b-finances/'
                    },
                    {
                        text: 'Туризм',
                        href: '/content/b2b-tourism/'
                    }
                ]
            }
        },
        methods: {
            scrollToEllement (event) {
                const href = event.target.getAttribute("href")
                const element = document.querySelector(href)

                element.scrollIntoView({ behavior: 'smooth' })
            },
            toggleSideMenu () {
                this.menuOpened = !this.menuOpened
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dark {
        height: 100vh;
        background: rgba(0, 0, 0, .3);
        position: fixed;
        top: 54px;
        left: 0px;
        height: 100vh;
        width: 100vw;
    }

    .dark-enter-active,
    .dark-leave-active {
        transition: .5s;
    }

    .dark-enter,
    .dark-leave-to {
        opacity: 0;
    }

    .header {
        position: fixed;
        z-index: 8;
        border-bottom: 1px solid #e8e8e8;
        top: 0;
        left: 0;
        width: 100%;
        height: 54px;
        background: #fff;
        box-sizing: border-box;

        @media (min-width: 1024px) {
            z-index: 7;
            height: 80px;
        }

        &__inner {
            max-width: 1170px;
            width: 100%;
            margin: 0 20px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            @media (min-width: 1200px) {
                margin: auto;
            }
        }

        &__logo {
            position: absolute;
            height: 24px;
            top: 27%;

            .workle-logo__main {
                fill: #c1c1c1;
            }

            @media (min-width: 1024px) {
                position: relative;
                top: 26px;
            }
        }

        &__horizontal-buttons {
            flex-grow: 0;
            flex-shrink: 0;
            position: relative;
            margin: 0;
            margin-top: 0;
            top: 19px;
            justify-content: flex-end;
            display: none;

            @media (min-width: 1024px) {
                display: flex;
                right: 40px;
                gap: 6px;
            }

            @media (min-width: 1200px) {
                right: 0;
                gap: 40px
            }
        }

        &__anchors {
            position: relative;
            display: none;
            top: 30px;

            @media (min-width: 1024px) {
                transform: translateX(-20px);
                display: flex;
                gap: 10px;
            }

            @media (min-width: 1200px) {
                gap: 16px;
            }
        }

        &__link {
            font-size: 18px;
            text-decoration: none;
            white-space: nowrap;
            position: relative;
            color: #333;

            @media (max-width: 1200px) and (min-width: 1024px) {
                font-size: 16px;
            }

            &:hover {
                color: #0ca9ef;
                fill: #0ca9ef
            }
        }

        &__spoiler-link {
            color: #333;

            &:hover {
                color: #0ca9ef;
            }
        }

        &__spoiler-arrow {
            width: 13px;
            transition: transform .3s;

            &--active {
                transform: rotate(180deg);
            }
        }

        &__spoiler {
            max-height: 22px;
            overflow: hidden;
            margin-left: 5px;
            padding: 0 25px;

            &--active {
                margin-left: 5px;
                background: white;
                padding: 25px;
                position: relative;
                top: -25px;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, .15);
                border-radius: 14px;
                max-height: 700px;
                max-width: unset;
            }
        }

        &__spoiler-title {
            margin: 0;
        }

        &__spoiler-link {
            display: block;
            text-decoration: none;
            font-size: 18px;
            margin-top: 10px;
        }

        &__button-item {
            width: 120px;

            &--wide {
                width: 200px;
                margin-right: 0;
            }

            &--low {
                height: 40px;
            }
        }

        &__navigation {
            position: absolute;
            right: 50px;

            @media (min-width: 1024px) {
                display: none
            }
        }
    }

    .navigation {
        &__side-menu {
            display: block;
            position: absolute;
            margin-top: 0px;
            top: 20px;
            left: 86.5%;
            width: 32px;
            z-index: 11;
            user-select: none;
        }

        &__side-menu input:checked {
            & ~ ul {
                transform: translate(-245px, 0);
            }

            & ~ span {
                opacity: 1;
                transform: rotate(45deg) translate(-2px, -1px);
                background: #232323;
            }

            & ~ span:nth-last-child(3) {
                opacity: 0;
                transform: rotate(0) scale(.2, .2);
            }

            & ~ span:nth-last-child(2) {
                transform: rotate(-45deg) translate(0, -1px);
            }
        }

        &__checkbox {
            display: block;
            width: 32px;
            height: 32px;
            position: absolute;
            top: -7px;
            left: -5px;
            cursor: pointer;
            opacity: 0;
            z-index: 2;
        }

        &__icon {
            display: block;
            width: 33px;
            height: 2px;
            margin-bottom: 5px;
            position: relative;
            background: #333;
            border-radius: 3px;
            z-index: 1;
            transform-origin: 4px 0;
            transition: transform .5s cubic-bezier(.77, .2, .05, 1),
                background .5s cubic-bezier(.77, .2, .05, 1),
                opacity .55s ease;

            &:first-child {
                transform-origin: 0 0;
            }

            &:nth-last-child(2) {
                transform-origin: 6.5px 100%;
            }
        }

        &__arrow {
            width: 13px;
            transform: rotate(-90deg);
            margin-left: 108px;

            &--back {
                transform: rotate(180deg);
                margin-left: 0;
            }
        }

        &__menu {
            position: absolute;
            top: 33px;
            left: 54px;
            width: 245px;
            height: 100vh;
            background: #fff;
            transition: transform .5s cubic-bezier(.77, .2, .05, 1);
            padding: 25px 0 40px;
            z-index: 11;
            overflow: hidden;
        }

        &__menu-slide {
            display: flex;
            width: 490px;
            transition: transform .2s ease-in-out;

            &--right {
                transform: translateX(-245px);
            }
        }

        &__menu-left,
        &__menu-right {
            width: 245px;
            display: flex;
            flex-direction: column;
        }

        &__link {
            font-weight: bold;
            font-size: 16px;
            color: #333;
            padding: 0 0 30px 20px;
            text-decoration: none;
            margin: 0;

            &:active ~ .header__spoiler-wrapper{
                background: red;
            }

            &--back {
                padding-left: 4px;
            }
        }

        &__button {
            text-decoration: none;
            padding: 0 0 10px 20px;
        }

        &__button-item {
            width: 200px;
            font-size: 16px;
            height: 40px;
        }
    }
</style>
